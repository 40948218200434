<template>
  <div>
    <label :for="id">{{ label }}</label>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'styled-label',

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
label {
  color: #7a7a7a;
}
</style>
