<template>
  <div class="no-collapse">
    <div class="flex-layout" :style="cssVars">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'flex-layout',
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    vgap: {
      type: String,
      default: '4rem',
    },
    hgap: {
      type: String,
      default: '2rem',
    },
  },

  computed: {
    cssVars() {
      return {
        '--columns': this.columns,
        '--vgap': this.vgap,
        '--hgap': this.hgap,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-layout {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  margin-top: calc(-1 * var(--vgap));
  margin-left: calc(-1 * var(--hgap));
  > * {
    margin-left: var(--hgap);
    margin-top: var(--vgap);
    flex-basis: calc(100% / var(--columns) - var(--hgap));
    flex-grow: 1;
  }
}
.no-collapse {
  padding: 0.05px;
}
</style>
