<template>
  <component v-bind:is="cmp" v-bind:step="step"></component>
</template>

<script>
import IntermediateStep from './intermediate.vue';
import Beer from './beer/beer.vue';
import Quiz from './quiz/quiz.vue';
import { INTERMEDIATE, BEER, QUIZ } from './consts';

const STEPS = {
  [INTERMEDIATE]: IntermediateStep,
  [BEER]: Beer,
  [QUIZ]: Quiz,
};

export default {
  computed: {
    cmp() {
      return STEPS[this.step.__component]; // eslint-disable-line no-underscore-dangle
    },
  },

  props: {
    step: {
      type: Object,
      require: true,
    },
  },
};
</script>
