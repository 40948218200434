<template>
  <guide-card v-bind:heading="welcomePage.Name">
    <flex-layout>
      <div class="column">
        <H2 color="#fff">{{ welcomePage.Headline }}</H2>
        <div v-html="welcomePage.Description" />
      </div>

      <div class="column">
        <img
          class="col-12"
          v-if="welcomePage.DescriptionImage"
          :src="welcomePage.DescriptionImage.url"
          :alt="welcomePage.DescriptionImage.alternativeText"
        />
      </div>
    </flex-layout>
    <styled-button centered v-on:click="onStartJourney">
      {{ buttonText }}
    </styled-button>
  </guide-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import flexLayout from '@/components/layout/flexLayout.vue';
import guideCard from '@/components/guide/card.vue';
import styledButton from '@/components/common/styledButton';
import { H2 } from '@/components/common/styledHeading';

export default {
  name: 'guide-welcome',

  components: {
    flexLayout,
    guideCard,
    styledButton,
    H2,
  },

  data() {
    return { startWithStep: 0 };
  },

  computed: {
    ...mapGetters({
      journeyData: 'guide/getJourney',
    }),
    welcomePage() {
      return this.journeyData.WelcomePage;
    },
    buttonText() {
      if (this.startWithStep > 0) return this.$t('guide.buttons.resumeJourney');
      return (
        this.journeyData.Steps[0]?.IntermediateStep?.ButtonText ||
        this.$t('guide.buttons.startJourney')
      );
    },
  },

  async mounted() {
    this.setTheme({
      pageBackgroundImage: this.journeyData.WelcomePage.BackgroundImage?.url || '',
    });
    const journeyId = this.journeyData.JourneyNumber;
    const lastStep = Number(localStorage.getItem(`${journeyId}-step`) || 0);
    if (lastStep) {
      this.startWithStep = lastStep;
    }
  },

  methods: {
    ...mapActions({
      setTheme: 'general/setTheme',
    }),
    onStartJourney() {
      this.$router.push({ query: { step: this.startWithStep } });
    },
  },
};
</script>
<style>
.column {
  min-width: 20rem;
}
</style>
