<template>
  <guide-card v-bind:heading="journeyData.Name">
    <flex-layout>
      <div class="column" v-html="journeyData.Conclusion" />
      <div class="column">
        <div class="beer">
          <H2 color="#fff">{{ $t('guide.yourTopBeers') }}</H2>
          <ul>
            <li v-for="beer in ratedBeer" :key="beer.id">
              <icon name="crown" />
              <span>{{ beer.Name }}</span>
              <rating name="beer" noStroke :value="beer.userRating" size="1rem" vertical />
            </li>
          </ul>
        </div>
        <div class="social" v-if="filteredSocials.length">
          <ul>
            <li v-for="social in filteredSocials" :key="social.id">
              <a :href="social.Link" :alt="social.Text" target="_blank">
                <img :src="social.Icon && social.Icon.url" :alt="social.Text" />
                <Hint>{{ social.Text }}</Hint>
              </a>
            </li>
          </ul>
        </div>
        <div class="feedback">
          <H2 color="#fff">{{ $t('guide.feedback') }}</H2>
          <form @submit.prevent="handleFormSubmit">
            <flex-layout vgap="0.5rem" hgap="0.5rem">
              <styled-label id="feedback-name" label="Name">
                <styled-input id="feedback-name" placeholder="Name" v-model="formData.Name" />
              </styled-label>
              <styled-label id="feedback-mail" label="E-Mail">
                <styled-input
                  id="feedback-mail"
                  placeholder="E-Mail"
                  v-model="formData.Email"
                  type="email"
                  :pattern="emailPattern"
                />
              </styled-label>
              <styled-label id="feedback-message" label="Dein Feedback">
                <styled-textarea
                  id="feedback-message"
                  placeholder="Dein Feedback"
                  v-model="formData.Message"
                />
              </styled-label>
            </flex-layout>
            <styled-button centered :disabled="formDisabled" type="submit">{{
              buttonText
            }}</styled-button>
          </form>
        </div>
      </div>
    </flex-layout>
  </guide-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import icon from 'vue-awesome/components/Icon.vue';
import flexLayout from '@/components/layout/flexLayout.vue';
import styledTextarea from '@/components/common/styledTextarea';
import styledInput from '@/components/common/styledInput';
import styledLabel from '@/components/common/styledLabel.vue';
import rating from '@/components/common/rating.vue';
import guideCard from '@/components/guide/card.vue';
import styledButton from '@/components/common/styledButton';
import { H2, Hint } from '@/components/common/styledHeading';
import { EMAIL_PATTERN } from '@/utils/patterns';

export default {
  name: 'guide-summary',

  components: {
    flexLayout,
    styledTextarea,
    styledInput,
    styledLabel,
    icon,
    rating,
    guideCard,
    styledButton,
    H2,
    Hint,
  },

  data() {
    return {
      isLoading: true,
      ratedBeer: [],
      formData: {},
      status: 'pending',
    };
  },

  created() {
    this.emailPattern = EMAIL_PATTERN;
  },

  computed: {
    ...mapGetters('guide', {
      journeyData: 'getJourney',
      journeyId: 'getJourneyId',
      journeyBeer: 'getJourneyBeers',
      socialMedias: 'getSocialMedias',
    }),
    filteredSocials() {
      if (!this.isLoading) {
        return this.journeyData.SocialMedias.map((item) => this.socialMedias[item.id]);
      }

      return [];
    },
    formDisabled() {
      return (
        !this.formData.Name ||
        !this.formData.Email?.match(this.emailPattern) ||
        !this.formData.Message ||
        this.status === 'loading' ||
        this.status === 'success'
      );
    },
    buttonText() {
      if (this.status === 'success') return this.$t('guide.messages.feedback.success');
      if (this.status === 'failure') return this.$t('guide.messages.feedback.failure');
      if (this.status === 'pending') return this.$t('guide.buttons.feedback');
      return this.buttonText;
    },
  },

  async mounted() {
    this.isLoading = true;

    const ratings = JSON.parse(localStorage.getItem(`${this.journeyId}-rating`) || '{}');
    this.ratedBeer = this.journeyBeer
      .map((beer) => ({ ...beer, userRating: ratings[beer.id] }))
      .sort((a, b) => b.userRating - a.userRating);

    this.setTheme({
      pageBackgroundImage: this.journeyData.WelcomePage.BackgroundImage?.url || '',
    });

    await this.fetchSocialMedias();

    this.isLoading = false;
  },

  methods: {
    ...mapActions({
      setTheme: 'general/setTheme',
      fetchSocialMedias: 'guide/fetchSocialMedias',
      fetchFeedback: 'guide/fetchFeedback',
    }),
    async handleFormSubmit() {
      this.formData.JourneyNumber = this.journeyId;
      this.status = 'loading';
      const resp = await this.fetchFeedback(this.formData);
      this.status = resp ? 'success' : 'failure';
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
}

.beer {
  ul {
    display: inline-block;
  }

  li {
    display: flex;
    align-items: center;
    margin: 0;
    > span {
      margin: 0 1rem 0 0.5rem;
      flex-grow: 1;
    }
  }
}

.social {
  margin-top: 3rem;
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
  }
  li {
    margin: 0.5rem 1rem;
    flex-grow: 0;
  }

  img {
    width: auto;
    height: 2.5rem;
  }

  a {
    text-align: center;
    display: inline-block;
  }
}

.feedback {
  margin-top: 3rem;

  form {
    max-width: 30rem;
    margin: 2rem auto 0;
  }

  input {
    min-width: 10rem;
  }

  input:invalid {
    border-color: red;
    background-color: #fdd;
  }

  textarea {
    font-family: inherit;
    resize: vertical;
    width: 100%;
  }

  button {
    width: 100%;
  }
}

.fa-icon {
  fill: #6ec1e4;
}
.column {
  min-width: 20rem;
}
</style>
