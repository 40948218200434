/**
 * This is a customer form configuration object. It should simplfy form components.
 * required - true when form field is required
 * type - type of the form field
 * label - i18n key for the label
 * placeholder - optional, i18 key for the placeholder
 */

const guideLoginForm = {
  JourneyNumber: {
    required: true,
    type: 'text',
    label: 'guide.forms.login.journey',
    placeholder: '',
    className: 'col-12',
  },
  JourneyPassword: {
    required: true,
    type: 'password',
    label: 'guide.forms.login.password',
    placeholder: '',
    className: 'col-12',
  },
  CustomerMail: {
    required: true,
    type: 'email',
    label: 'guide.forms.login.email',
    placeholder: '',
    className: 'col-12',
  },
  agbConfirmation: {
    required: true,
    type: 'checkbox',
    label: 'guide.forms.login.agb',
    placeholder: '',
    className: 'col-12',
    agbLink: '/agb',
    privacyLink: '/datenschutz',
  },
};

export { guideLoginForm as default };
