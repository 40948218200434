import styled from 'vue-styled-components';

const textareaProps = {
  width: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
    default: '8rem',
  },
};

const styledTextarea = styled('textarea', textareaProps)`
  width: 100%;
  max-width: ${props => props.width};
  min-height: ${props => props.height};
  border: 0.05rem solid ${props => props.theme.lightGrey};
  border-radius: 0.125rem;
  padding: 0.75rem;
  font-size: 1rem;
  color: ${props => props.theme.darkGrey};
  transition: .5s ease all;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primaryColor}
  }
`;

export { styledTextarea as default };
