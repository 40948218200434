const ratingForm = [{
    key: 'Presentation',
    title: 'guide.beer.presentation',
    tooltip: 'guide.beer.presentationTooltip',
    category: 'guide.beer.categoryLook',
}, {
    key: 'Contemporary',
    title: 'guide.beer.contemporary',
    tooltip: 'guide.beer.contemporaryTooltip',
    category: 'guide.beer.categoryLook',
}, {
    key: 'SmellIntensity',
    title: 'guide.beer.smellIntensity',
    tooltip: 'guide.beer.smellIntensityTooltip',
    category: 'guide.beer.categorySmell',
}, {
    key: 'Acerbity',
    title: 'guide.beer.acerbity',
    tooltip: 'guide.beer.acerbityTooltip',
    category: 'guide.beer.categorySmell',
}, {
    key: 'Fruitness',
    title: 'guide.beer.fruitness',
    tooltip: 'guide.beer.fruitnessTooltip',
    category: 'guide.beer.categoryTaste',
}, {
    key: 'Wort',
    title: 'guide.beer.wort',
    tooltip: 'guide.beer.wortTooltip',
    category: 'guide.beer.categoryTaste',
}, {
    key: 'FirstSipAroma',
    title: 'guide.beer.firstSipAroma',
    tooltip: 'guide.beer.firstSipAromaTooltip',
    category: 'guide.beer.categoryMouthfeel',
}, {
    key: 'AfterSipAroma',
    title: 'guide.beer.afterSipAroma',
    tooltip: 'guide.beer.afterSipAromaTooltip',
    category: 'guide.beer.categoryMouthfeel',
},
];

export { ratingForm as default };
