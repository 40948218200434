<template>
  <card class="p-xl">
    <H1 color="#fff" class="m-b-xl" fontWeight="bold">{{ heading }}</H1>

    <div class="flex">
      <div v-bind:class="{ 'col-8': $slots.navigation }">
        <slot />

        <slot name="extras" />
      </div>
      <div class="col-4 relative" v-if="$slots.navigation">
        <slot name="navigation" />
      </div>
    </div>
  </card>
</template>

<script>
import card from '@/components/common/card';
import { H1 } from '@/components/common/styledHeading';

export default {
  components: {
    card,
    H1,
  },

  props: {
    heading: {
      type: String,
      required: true,
    },
  },
};
</script>
