<template>
  <div class="h-full">
    <nav v-if="!$slots.overlay">
      <div>
        <ol>
          <li v-for="(step, index) in steps" v-bind:key="index">
            <router-link
              :to="{ query: { step: index } }"
              exact
              :class="{ inactive: index > progress }"
              >{{ step.Name }}</router-link
            >
          </li>
        </ol>
      </div>
      <div class="content-buttons">
        <slot name="buttons" />
      </div>

      <flex-layout vgap="1rem" hgap="1rem" :columns="2">
        <styled-button
          :disabled="currentStep < 1"
          v-on:click="handlePrevStep"
          class="previous-step"
        >
          {{ $t('guide.buttons.prevStep') }}
        </styled-button>
        <tooltip :show="nextStepDisabled" :text="$t('guide.tooltips.rateFirst')" class="next-step">
          <styled-button :disabled="nextStepDisabled" v-if="buttonText" v-on:click="handleNextStep">
            {{ buttonText }}
          </styled-button>
        </tooltip>
      </flex-layout>
    </nav>

    <slot name="overlay" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import styledButton from '@/components/common/styledButton';
import tooltip from '@/components/common/tooltip.vue';
import flexLayout from '@/components/layout/flexLayout.vue';
import * as STEPS from './steps/consts';

export default {
  data() {
    return {
      progress: -1,
    };
  },

  components: {
    styledButton,
    tooltip,
    flexLayout,
  },

  computed: {
    ...mapGetters({
      steps: 'guide/getSteps',
      journeyId: 'guide/getJourneyId',
    }),
    currentStep() {
      return Number(this.$route.query.step);
    },
    nextStep() {
      return Number(this.$route.query.step) + 1;
    },
    buttonText() {
      /* eslint-disable no-underscore-dangle */

      if (this.nextStep === this.steps.length) {
        return this.$t('guide.buttons.summary');
      }

      const current = this.steps[this.currentStep];
      const next = this.steps[this.nextStep];

      if (current.__component === STEPS.QUIZ) {
        return this.$t('guide.buttons.skipQuiz');
      }

      if (next?.IntermediateStep?.ButtonText) {
        return next?.IntermediateStep?.ButtonText;
      }

      switch (next?.__component) {
        case STEPS.BEER: {
          const firstBeerIdx = this.steps.findIndex((s) => s?.__component === STEPS.BEER);
          const isFirstBeer = firstBeerIdx === this.nextStep;
          return this.$t(isFirstBeer ? 'guide.buttons.firstBeer' : 'guide.buttons.nextBeer');
        }
        case STEPS.QUIZ:
          return this.$t('guide.buttons.quiz');
        default:
          return this.$t('guide.buttons.nextStep');
      }
    },
  },

  props: {
    customButtonText: {
      type: String,
    },
    nextStepDisabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    currentStep() {
      this.saveProgress();
    },
  },

  methods: {
    handlePrevStep() {
      this.$router.push({ query: { step: this.currentStep - 1 } });
    },
    handleNextStep() {
      if (this.nextStep < this.steps.length) {
        this.$router.push({ query: { step: this.nextStep } });
      } else {
        this.$router.push({ query: { step: 'summary' } });
      }
    },
    saveProgress() {
      this.progress = localStorage.getItem(`${this.journeyId}-progress`);

      if (!this.progress) this.progress = -1;

      if (this.currentStep > this.progress) {
        localStorage.setItem(`${this.journeyId}-progress`, this.currentStep);
        this.progress = this.currentStep;
      }
    },
  },

  mounted() {
    this.saveProgress();
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  height: 100%;
  padding-left: 1.5rem;
}

@media screen and (max-width: 35rem) {
  nav {
    margin-top: 4rem;
    padding-left: inherit;
    height: auto;
  }
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

li {
  border-bottom: 1px solid #cad2dbab;
  margin: 0;
}

a {
  display: inline-block;
  color: #cad2dbab;
  padding: 4px 6px;
  margin: 0.4rem 0;
  text-decoration: none;
}

a.router-link-active {
  font-weight: bold;
  font-size: 1.1rem;
}

a:hover {
  font-weight: bold;
}

a.inactive {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.h-full {
  height: 100%;
}

.content-buttons {
  flex-grow: 1;
  margin: 2rem 0;

  > button {
    margin: 1rem 0;
    width: 100%;
  }
}

.previous-step {
  min-width: 12rem;
  flex-shrink: 1;
  /* flex-basis: auto; */
}
.next-step {
  min-width: 12rem;
  /* flex-basis: auto; */
}
</style>
