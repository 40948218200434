<template>
  <div class="container-lg" v-if="loggedInUser && !isLoading && journeyData">
    <welcome v-if="journeyData.WelcomePage && step == null" />
    <steps v-if="step != null && step !== 'summary'" v-bind:step="journeyData.Steps[step]" />
    <beer-summary v-if="step === 'summary'" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const welcome = () => import(/* webpackChunkName: "guide" */ './welcome.vue');
const steps = () => import(/* webpackChunkName: "guide" */ './steps/index.vue');
const beerSummary = () => import(/* webpackChunkName: "guide" */ './summary.vue');

export default {
  name: 'guide-journey',

  components: {
    welcome,
    steps,
    beerSummary,
  },

  props: {
    step: {
      type: String,
    },
    journeyId: {
      type: String,
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('guide', {
      journeyData: 'getJourney',
      loggedInUser: 'getLoggedInUser',
    }),
  },

  watch: {
    $route() {
      if (!this.loggedInUser.jwt) {
        this.logout();
      } else {
        this.rememberStep();
      }
    },
  },

  async mounted() {
    this.rememberStep();

    if (!this.loggedInUser.jwt) {
      this.logout();
    } else {
      this.isLoading = true;
      await this.fetchJourney({ journeyId: this.journeyId });
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions('guide', {
      fetchJourney: 'fetchJourney',
    }),
    rememberStep() {
      if (this.step) localStorage.setItem(`${this.journeyId}-step`, this.step);
    },
    logout() {
      localStorage.removeItem('latestJourney');
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(this.journeyId)) localStorage.removeItem(key);
      });

      this.$router.push({ name: 'login' });
    },
  },
};
</script>
