<template>
  <div class="rating">
    <div v-for="result in results" :key="result.id" class="question">
      <p>
        {{ result.question }} –
        <span :class="result.answer.IsCorrect ? 'correct' : 'wrong'"
          >[{{ $t(result.answer.IsCorrect ? 'guide.quiz.correct' : 'guide.quiz.wrong') }}]</span
        >
        {{ result.answer.Answer }}
      </p>
      <p>{{ result.solution }}</p>
    </div>

    <ratingStars :value="stars" />
    <p>{{ rating }}</p>
  </div>
</template>

<script>
import ratingStars from '@/components/common/rating.vue';

const ratingKeys = ['oneStar', 'twoStar', 'threeStar', 'fourStar', 'fiveStar'];

export default {
  name: 'result',

  components: {
    ratingStars,
  },

  props: {
    questions: {
      type: Array,
      require: true,
    },

    userAnswers: {
      type: Object,
      require: true,
    },

    ratings: {
      type: Object,
      require: true,
    },
  },

  data() {
    const results = this.questions.map((item) => ({
      id: item.id,
      question: item.Question,
      answer: item.Answers.find((a) => a.id === this.userAnswers[item.id]),
      solution: item.Solution,
    }));
    const validOnes = results.filter((item) => item.answer);
    const correctOnes = validOnes.filter((item) => item.answer.IsCorrect);
    const ratingFloat = (correctOnes.length / validOnes.length) * 4;
    const ratingKey = ratingKeys[Math.round(ratingFloat)];
    const rating = this.ratings[ratingKey];

    return { results, rating, stars: Number((ratingFloat + 1).toFixed(1)) };
  },
};
</script>

<style lang="scss" scoped>
.rating {
  margin-top: 2rem;
}
.question {
  margin: 1.5rem 0;
}
.correct {
  font-weight: bolder;
  color: #0f0;
}
.wrong {
  font-weight: bolder;
  color: #f00;
}
</style>
