<template>
  <guide-card v-if="!isLoading" :heading="intermediateData.Name">
    <div>
      <video
        v-if="videoUrl"
        :src="videoUrl"
        :poster="
          intermediateData.DescriptionVideoPreview && intermediateData.DescriptionVideoPreview.url
        "
        controls
        @contextmenu.prevent
      />
    </div>

    <H2 color="fff" class="m-t-xl">{{ intermediateData.Headline }}</H2>
    <div v-html="intermediateData.Description" />

    <template v-slot:navigation>
      <navigation />
    </template>
  </guide-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import guideCard from '@/components/guide/card.vue';
import { H2 } from '@/components/common/styledHeading';
import navigation from '../navigation.vue';

export default {
  data() {
    return {
      isLoading: true,
    };
  },

  components: {
    guideCard,
    H2,
    navigation,
  },

  props: {
    step: {
      type: Object,
      require: true,
    },
  },

  computed: {
    ...mapGetters({
      intermediateData: 'guide/getIntermediate',
    }),
    intermediateId() {
      return this.step.IntermediateStep.id;
    },
    videoUrl() {
      return this.intermediateData?.DescriptionVideo?.url;
    },
  },

  watch: {
    async intermediateId() {
      await this.fetchIntermediate(this.intermediateId);
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.fetchIntermediate(this.intermediateId);
    this.setTheme({
      pageBackgroundImage: this.intermediateData.BackgroundImage?.url || '',
    });
    this.isLoading = false;
  },

  methods: {
    ...mapActions({
      setTheme: 'general/setTheme',
    }),
    ...mapActions('guide', {
      fetchIntermediate: 'fetchIntermediate',
    }),
  },
};
</script>
