import styled from 'vue-styled-components';

const cardHeaderProps = {
  paddingX: {
    type: String,
    default: '1.5rem',
  },
};

const cardHeader = styled('div', cardHeaderProps)`
  background-color: ${ props => props.theme.darkGrey };
  color: ${ props => props.theme.white };
  padding: 0.8rem ${ props => props.paddingX };
  font-size: 1.4rem;
  line-height: 1.2;
  text-align: center;
  position: relative;

  span {
    color: ${ props => props.theme.secondaryColor };
    font-weight: bold;
  }
`;

export { cardHeader as default };
