const guideTheme = {
  primaryColor: '#18391f',
  secondaryColor: '#f9f19a',
  pageBackgroundImage: '',
  backgroundColor: '#0C0B0B',

  white: '#ffffff',
  lightGrey: '#E3E3E3',
  darkGrey: '#404040',
  darkerGrey: '#1c2125',

  defaultShadow: '7px 9px 10px rgba(0,0,0,0.5)',
  defaultBorderRadius: '4px',

  cardBackground: '#1c2125cf',
  cardTextColor: '#ffffff',
};

export { guideTheme as default };
