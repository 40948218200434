<template>
  <wrapper
    v-if="!isLoading"
    :backgroundImage="theme.pageBackgroundImage"
    class="flex column center"
  >
    <router-view />
  </wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cookies from '@/utils/cookies';
import { setConversionCookie } from '@/utils/tracking';

import wrapper from '@/components/common/wrapper';
import guideTheme from '@/themes/guide';

export default {
  components: {
    wrapper,
  },

  computed: {
    ...mapGetters({
      configuratorData: 'configurators/getConfiguratorData',
      theme: 'general/getTheme',
    }),
  },

  data() {
    return {
      isLoading: true,
      configuratorUID: 'bier', // this.$route.params.configuratorUID,
    };
  },

  async mounted() {
    setConversionCookie(this.$route.query);
    this.isLoading = true;
    await this.fetchConfiguratorData({ configuratorUID: this.configuratorUID });
    // due we get theme information based on configurator fetch we need to set it here
    this.setTheme({
      ...guideTheme,
      logoUrl: this.configuratorData.CityLogo?.url,
    });

    const oldJwt = cookies.get('guidetoken');
    this.setLoggedInUser(oldJwt);

    this.isLoading = false;
  },

  methods: {
    ...mapActions({
      fetchConfiguratorData: 'configurators/fetchConfiguratorData',
      setTheme: 'general/setTheme',
      setLoggedInUser: 'guide/setLoggedInUser',
    }),
  },
};
</script>
