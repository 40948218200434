<template>
  <div class="tooltip-wrapper">
    <div v-if="show" class="tooltip">{{ text }}</div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'tooltip',

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip-wrapper {
  position: relative;
  z-index: 2;
  display: inline-block;

  > * {
    width: 100%;
  }

  > .tooltip {
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-out;
    transform: translate(-50%, 6px);
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 8px;
    padding: 0.5rem;
    min-width: 10rem;
    max-width: 15rem;
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    font-size: 1rem;
    line-height: 1.25;

    &:after {
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      border-top: 6px solid rgba(0, 0, 0, 0.8);
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      content: ' ';
      font-size: 0;
      line-height: 0;
    }
  }

  &:hover > .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
</style>
