<template>
  <card class="col-8 p-md">
    <card-header>
      <H1 class="text-center">{{ $t('guide.forms.login.heading') }}</H1>
      <SubH>{{ $t('guide.forms.login.subheading') }}</SubH>
    </card-header>

    <form class="flex">
      <span class="error" v-if="this.loggedInUser.error">{{ this.loggedInUser.errorMessage }}</span>
      <div
        v-for="(formField, name) in guideLoginForm"
        :key="name"
        :class="`${formField.className} p-xs`"
      >
        <input
          v-if="formField.type === 'checkbox'"
          type="checkbox"
          v-model="inputData[name]"
          :id="name"
          class="float-left"
        />
        <label :for="name" class="m-b-xxs block">
          <!--ToDo: We should get those links by an api call -->
          <span
            class="checkbox-link"
            v-html="
              $t(formField.label, {
                agbLink: formField.agbLink,
                privacyLink: formField.privacyLink,
              })
            "
          ></span>
        </label>
        <styled-input
          v-if="isInput(formField.type)"
          :type="formField.type"
          :name="name"
          v-model="inputData[name]"
          :required="formField.required"
          :id="name"
        />
      </div>

      <tooltip :show="disabled" :text="$t('guide.tooltips.fillAll')" class="tooltip">
        <styled-button
          :disabled="disabled"
          type="button"
          @click.prevent="handleSubmit(guideLoginForm, inputData)"
        >
          {{ $t('guide.forms.login.submit') }}
        </styled-button>
      </tooltip>
    </form>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cookies from '@/utils/cookies';

import styledInput from '@/components/common/styledInput';
import styledButton from '@/components/common/styledButton';
import cardHeader from '@/components/common/cardHeader';
import { H1, SubH } from '@/components/common/styledHeading';
import card from '@/components/common/card';
import tooltip from '@/components/common/tooltip.vue';
import guideLoginForm from '@/config/guide/loginForm';
import { isInput } from '@/utils/components';

export default {
  name: 'guide-login',

  components: {
    styledInput,
    styledButton,
    H1,
    SubH,
    cardHeader,
    card,
    tooltip,
  },

  data() {
    return {
      inputData: {},
      error: '',
      guideLoginForm,
    };
  },

  watch: {
    loggedInUser() {
      if (this.loggedInUser.jwt !== '' && !this.loggedInUser.error) {
        cookies.set('guidetoken', this.loggedInUser.jwt);
        localStorage.setItem('latestJourney', this.inputData.JourneyNumber);
        localStorage.setItem('userMail', this.inputData.CustomerMail);

        if (this.path) {
          this.$router.push({ path: this.path });
        } else {
          this.$router.push({ name: 'guide', params: { journeyId: this.inputData.JourneyNumber } });
        }
      }
    },
  },

  mounted() {
    const latestJourney = localStorage.getItem('latestJourney');
    const userCookie = cookies.get('guidetoken');

    if (latestJourney && userCookie) {
      this.$router.push({ name: 'guide', params: { journeyId: latestJourney } });
    }
  },

  computed: {
    ...mapGetters('guide', {
      loggedInUser: 'getLoggedInUser',
    }),
    disabled() {
      return !Object.keys(this.guideLoginForm).reduce(
        (acc, key) => acc && this.inputData[key],
        true,
      );
    },
  },

  methods: {
    ...mapActions('guide', {
      login: 'fetchLogin',
    }),

    isInput,

    checkForRequiredFields(formFields, inputData) {
      return Object.keys(formFields).filter((key) => formFields[key].required && !inputData[key]);
    },

    async handleSubmit(formFields, inputData) {
      this.isLoading = true;

      if (inputData.agbConfirmation) {
        const errors = this.checkForRequiredFields(formFields, inputData);

        if (!errors.length) {
          await this.login(inputData);
        }

        this.errors = errors;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.checkbox-link a {
  color: white;
}

.error {
  display: inline-block;
  color: white;
  font-weight: bold;
  padding: 0.5rem;
  background-color: rgba(255, 0, 0, 0.6);
  width: 100%;
}

.tooltip {
  margin: 1rem auto 0;
}
</style>
