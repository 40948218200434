import styled from 'vue-styled-components';

const buttonProps = {
  fullWidth: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: '1rem',
  },
  backgroundColor: {
    type: String,
    default: null,
  },
  fontSize: {
    type: String,
    default: '1.25rem',
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
};

const styledButton = styled('button', buttonProps)`
  display: block;
  cursor: pointer;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  border: none;
  box-shadow: ${props => props.theme.defaultShadow};
  padding: ${props => props.padding};
  background-color: ${props => (props.inverted ? props.theme.secondaryColor : props.theme.primaryColor)};
  border-radius: ${props => props.theme.defaultBorderRadius};
  font-size: ${props => props.fontSize};
  font-weight: bold;
  color: ${props => (props.inverted ? props.theme.primaryColor : '#ffffff')};
  transition: .5s ease;
  margin: ${props => (props.centered ? '1rem auto 0' : 'initial')};

  &:focus {
    outline: none;
    transform: scale(1.01);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export { styledButton as default };
