const allowedInputTypes = ['text', 'tel', 'email', 'date', 'time', 'password'];

/**
 * Returns true if a input e.g. 'tel' type should be handled like normal input
 * Useful for choosing the correct component when iterating through form configuration files.
 * @param {String} inputType - the input type of the component to be checked
 */
/* eslint-disable import/prefer-default-export */
export function isInput(inputType) {
  return allowedInputTypes.includes(inputType);
}
