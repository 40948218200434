import styled from 'vue-styled-components';

const titleProps = {
  size: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: null,
  },
  center: {
    type: Boolean,
    default: false,
  },
  fontFamily: {
    type: String,
    default: 'inherit',
  },
  fontWeight: {
    type: String,
    default: null,
  },
  mb: {
    type: String,
    default: null,
  },
};

const H1 = styled('h1', titleProps)`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${(props) => (props.color ? props.color : props.theme.secondaryColor)};
  font-size: ${(props) => (props.size ? props.size : '2.5rem')};
  margin-bottom: ${(props) => (props.mb ? props.mb : undefined)}; ;
`;

const H2 = styled('h2', titleProps)`
  color: ${(props) => (props.color ? props.color : props.theme.primaryColor)};
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => (props.size ? props.size : '1.25rem')};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  line-height: 1.2;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0.75rem')}; ;
`;

const SubH = styled('h3', titleProps)`
  color: ${(props) => (props.color ? props.color : props.theme.secondaryColor)};
  font-size: ${(props) => (props.size ? props.size : '1.1rem')};
  font-weight: bold;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '1rem')};
  line-height: 1.3;
`;

const Price = styled('p')`
  color: ${(props) => props.theme.secondaryColor};
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
`;

const Hint = styled('p')`
  color: #fff;
  font-size: 1rem;
  margin-top: 0.25rem !important;
  margin-bottom: 0.75rem;
`;

export { H1, H2, SubH, Price, Hint };
