import styled from 'vue-styled-components';

const cardProps = {
  clickable: {
    type: Boolean,
    default: false,
  },
  overflowVisible: {
    type: Boolean,
    default: false,
  },
  themeBackground: {
    type: Boolean,
    default: false,
  },
};

const card = styled('div', cardProps)`
  overflow: ${(props) => (props.overflowVisible ? 'visible' : 'hidden')};
  box-shadow: ${(props) => props.theme.defaultShadow};
  backdrop-filter: ${(props) => (props.themeBackground ? undefined : 'blur(12px)')};
  border-radius: 5px;
  background-color: ${(props) =>
    props.themeBackground ? props.theme.primaryColor : props.theme.cardBackground};
  color: ${(props) => (props.themeBackground ? props.theme.white : props.theme.cardTextColor)};
  transition: 0.2s ease all;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'inherit')};

  ul {
    list-style: outside;
    margin-left: 1.5rem;
  }

  li {
    margin: 0.25rem 0;
  }

  p:not(:first-child),
  ul:not(:first-child) {
    margin-top: 0.5rem;
  }

  ${({ clickable }) => {
    if (clickable) {
      return `&:hover {
      box-shadow: 2px 2px 20px 15px rgba(0,0,0,0.06);
      transform: scale(1.03);
    }`;
    }
    return '';
  }}
`;

export { card as default };
