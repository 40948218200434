<template>
  <li>
    <icon name="check" />
    <span>{{ name }}:</span>
    <slot />
  </li>
</template>

<script>
import icon from 'vue-awesome/components/Icon.vue';

export default {
  name: 'detail-item',

  components: {
    icon,
  },

  props: {
    name: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.fa-icon {
  fill: #6ec1e4;
}

li {
  break-inside: avoid-column;
  display: flex;
  align-items: top;
  line-height: 1.4rem;

  > span {
    flex-shrink: 0;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    width: 9.5rem;
    font-weight: bolder;
    text-transform: uppercase;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  > svg {
    margin-top: 0.2rem;
  }
}
</style>
