import styled from 'vue-styled-components';

const wrapperProps = {
  backgroundImage: {
    type: String,
    default: '',
  },
};

const wrapper = styled('div', wrapperProps)`
  background-color: ${props => props.theme.backgroundColor};
  background-image: url(${props => (props.backgroundImage ? props.backgroundImage : null)});
  background-size: cover;
  padding: 2rem 0;
  flex-grow: 1;
`;

export { wrapper as default };
