<template>
  <guide-card v-if="!isLoading" :heading="beerData.Name">
    <video
      v-if="videoUrl"
      :src="videoUrl"
      :poster="beerData.TastingVideoPreview && beerData.TastingVideoPreview.url"
      controls
      @contextmenu.prevent
    />

    <beer-details :beerData="beerData" />

    <!--render rating solution without backend store-->
    <div class="rating" v-if="!rateable">
      <H2 color="#fff" size="1.25rem" mb="0">{{ $t('guide.didYouLikeIt') }}</H2>
      <Hint>{{ $t('guide.hints.rateFirst') }}</Hint>
      <rating
        name="beer"
        size="2.5rem"
        vertical
        clickable
        :value="beerRatings[step.Beer.id] ? beerRatings[step.Beer.id].general : 0"
        @change-rating="(value) => handleChangeRating('general', value)"
      />
    </div>

    <!--render rating solution with backend store-->
    <div class="rating flex" v-if="rateable">
      <div class="col-6 m-t-xxl m-b-xxl" v-for="part in ratingForm" :key="part.key">
        <H2 color="#fff" size="1.25rem" mb="0">{{ $t(part.category) }}: {{ $t(part.title) }}</H2>
        <Hint>{{ $t(part.tooltip) }}</Hint>
        <rating
          name="beer"
          size="2.5rem"
          vertical
          clickable
          :value="beerRatings[step.Beer.id] ? beerRatings[step.Beer.id][part.key] : 0"
          @change-rating="(value) => handleChangeRating(part.key, value)"
        />
        <p class="font-small">
          {{ $t('guide.beer.rating') }}
          <span v-if="beerRatings[step.Beer.id] && beerRatings[step.Beer.id][part.key]">
            {{
              $t('guide.beer.averageRatingInfo', {
                numOfRatings: averageRating.NumberOfRatings,
                points: averageRating[part.key],
              })
            }}
          </span>
        </p>
      </div>
    </div>

    <template v-slot:extras v-if="funfact">
      <div class="funfact">
        <H2 color="#fff" size="2.5rem" fontWeight="bold">{{ funfact.Name }}</H2>
        <div>
          <SubH color="#fff" class="m-t-xl">{{ funfact.Headline }}</SubH>
          <div v-html="funfact.Description" />
          <img
            v-if="funfact.DescriptionImage"
            :src="funfact.DescriptionImage.url"
            :alt="funfact.DescriptionImage.alt"
          />
          <video
            v-if="funfact.DescriptionVideo"
            :src="funfact.DescriptionVideo.url"
            :poster="funfact.DescriptionVideoPreview && funfact.DescriptionVideoPreview.url"
            controls
            @contextmenu.prevent
          />
        </div>
      </div>
    </template>

    <template v-slot:navigation>
      <navigation :nextStepDisabled="!beerRatings[step.Beer.id]">
        <template v-slot:buttons>
          <styled-button v-on:click="toggleBeerDetails">
            {{ $t('guide.buttons.beerInfos') }}
          </styled-button>
          <styled-button v-on:click="toggleBreweryDetails">
            {{ $t('guide.buttons.breweryInfos') }}
          </styled-button>
        </template>
        <template v-if="beerDetails || breweryDetails" v-slot:overlay>
          <detail-overlay v-show="beerDetails">
            <div>
              <img
                v-if="beerData.BeerDescriptionImage"
                :src="beerData.BeerDescriptionImage.url"
                :alt="beerData.BeerDescriptionImage.alt"
              />
              <div v-html="beerData.BeerDescription" />
            </div>

            <styled-button centered v-on:click="toggleBeerDetails">
              {{ $t('guide.buttons.closePopup') }}
            </styled-button>
          </detail-overlay>

          <detail-overlay v-show="breweryDetails">
            <div>
              <img
                v-if="beerData.BreweryDescriptionImage"
                :src="beerData.BreweryDescriptionImage.url"
                :alt="beerData.BreweryDescriptionImage.alt"
              />
              <div v-html="beerData.BreweryDescription" />
            </div>

            <styled-button centered v-on:click="toggleBreweryDetails">
              {{ $t('guide.buttons.closePopup') }}
            </styled-button>
          </detail-overlay>
        </template>
      </navigation>
    </template>
  </guide-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ratingForm from '@/config/guide/ratingForm';
import guideCard from '@/components/guide/card.vue';
import detailOverlay from '@/components/guide/detailOverlay.vue';
import rating from '@/components/common/rating.vue';
import { H2, SubH, Hint } from '@/components/common/styledHeading';
import styledButton from '@/components/common/styledButton';
import navigation from '../../navigation.vue';

import beerDetails from './details.vue';

export default {
  components: {
    guideCard,
    detailOverlay,
    rating,
    H2,
    SubH,
    Hint,
    styledButton,
    navigation,
    beerDetails,
  },

  props: {
    step: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      isLoading: true,
      beerDetails: false,
      breweryDetails: false,
      beerRatings: {},
      ratingForm,
    };
  },

  computed: {
    ...mapGetters({
      beerData: 'guide/getBeer',
      journeyId: 'guide/getJourneyId',
      user: 'guide/getLoggedInUser',
      rateable: 'guide/getJourneyRatingSetting',
      averageRating: 'guide/getAverageBeerRating',
    }),

    beerId() {
      return this.step.Beer.id;
    },

    videoUrl() {
      return this.beerData?.TastingVideo.url;
    },

    funfact() {
      return this.beerData?.Funfact;
    },
  },

  watch: {
    async beerId() {
      await this.fetchBeer(this.beerId);
    },
  },

  async mounted() {
    this.isLoading = true;
    this.beerRatings = JSON.parse(localStorage.getItem(`${this.journeyId}-rating`) || '{}');

    await this.fetchBeer(this.beerId);
    await this.fetchAverageRating({ beerId: this.beerId });

    this.setTheme({
      pageBackgroundImage: this.beerData.BackgroundImage?.url || '',
    });

    this.isLoading = false;
  },

  methods: {
    ...mapActions({
      setTheme: 'general/setTheme',
      fetchBeer: 'guide/fetchBeer',
      fetchAverageRating: 'guide/fetchAverageBeerRating',
      sendBeerRating: 'guide/sendBeerRating',
    }),

    toggleBeerDetails() {
      this.beerDetails = !this.beerDetails;
    },

    toggleBreweryDetails() {
      this.breweryDetails = !this.breweryDetails;
    },

    handleChangeRating(ratingId, value) {
      this.beerRatings = {
        ...this.beerRatings,
        [this.step.Beer.id]: { ...this.beerRatings[this.step.Beer.id], [ratingId]: value },
      };
      localStorage.setItem(`${this.journeyId}-rating`, JSON.stringify(this.beerRatings));

      if (this.rateable) {
        this.sendBeerRating({
          UserMail: localStorage.getItem('userMail'),
          beer: {
            _id: this.beerId,
          },
          ...this.beerRatings[this.beerId],
        });
      }
    },
  },
};
</script>

<style scoped>
.funfact,
.rating {
  margin-top: 5rem;
}

.funfact > h2 {
  margin-bottom: 2rem;
}

.extras {
  margin: 10rem;
}

.funfact video,
.funfact img {
  margin-top: 2rem;
}
</style>
