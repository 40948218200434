<template>
  <guide-card :heading="step.Quiz.Name">
    <div v-html="step.Quiz.Introduction" />
    <questions
      v-if="!isLoading && !userAnswers"
      :quizId="step.Quiz.id"
      :questions="quiz.Questions"
      @submit="handleAnswers"
    />
    <result
      v-if="!isLoading && userAnswers"
      :questions="quiz.Questions"
      :ratings="quiz.Rating"
      :userAnswers="userAnswers"
      :key="JSON.stringify(userAnswers)"
    />

    <template v-slot:navigation>
      <navigation />
    </template>
  </guide-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import guideCard from '@/components/guide/card.vue';
import navigation from '../../navigation.vue';
import questions from './questions.vue';
import result from './result.vue';

export default {
  name: 'quiz',

  components: {
    guideCard,
    navigation,
    questions,
    result,
  },

  data() {
    return {
      isLoading: true,
      userAnswers: undefined,
    };
  },

  props: {
    step: {
      type: Object,
      require: true,
    },
  },

  computed: {
    ...mapGetters({
      quiz: 'guide/getQuiz',
      journeyId: 'guide/getJourneyId',
    }),
    quizId() {
      return this.step.Quiz.id;
    },
  },

  watch: {
    async quizId() {
      await this.fetchQuiz(this.quizId);
      const userAnswers = JSON.parse(localStorage.getItem(`${this.journeyId}-quiz`) || '{}');
      this.userAnswers = userAnswers[this.quizId];
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.fetchQuiz(this.quizId);
    this.setTheme({
      pageBackgroundImage: this.step.Quiz.BackgroundImage?.url || '',
    });
    this.isLoading = false;
  },

  methods: {
    ...mapActions({
      setTheme: 'general/setTheme',
      fetchQuiz: 'guide/fetchQuiz',
    }),

    handleAnswers(answerIds) {
      this.userAnswers = answerIds;
    },
  },
};
</script>
