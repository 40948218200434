import styled from 'vue-styled-components';

const inputProps = {
  width: {
    type: String,
    default: '100%',
  },
};

const styledInput = styled('input', inputProps)`
  width: 100%;
  max-width: ${props => props.width};
  border: 0.05rem solid ${props => props.theme.lightGrey};
  border-radius: 0.125rem;
  padding: 0.75rem;
  font-size: 1rem;
  color: ${props => props.theme.darkGrey};
  transition: .5s ease all;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primaryColor};
  }
`;

export { styledInput as default };
