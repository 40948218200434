<template>
  <ul class="details">
    <detail-item :name="$t('guide.beer.brewery')">{{ beerData.Brewery }}</detail-item>
    <detail-item :name="$t('guide.beer.alcoholLevel')">{{ beerData.AlcoholLevel }}</detail-item>
    <detail-item :name="$t('guide.beer.ibuLevel')">{{ beerData.IBULevel }}</detail-item>
    <detail-item :name="$t('guide.beer.beerStyle')">{{ beerData.BeerStyle }}</detail-item>
    <detail-item :name="$t('guide.beer.goesWellWith')">{{ beerData.GoesWellWith }}</detail-item>
    <detail-item :name="$t('guide.beer.optic')">{{ beerData.Optic }}</detail-item>
    <detail-item :name="$t('guide.beer.flavorAroma')">{{ beerData.FlavorAroma }}</detail-item>
    <detail-item :name="$t('guide.beer.mouthfeel')">{{ beerData.Mouthfeel }}</detail-item>
    <detail-item :name="$t('guide.beer.firstSip')">{{ beerData.FirstSip }}</detail-item>
    <detail-item :name="$t('guide.beer.taste')">{{ beerData.Taste }}</detail-item>
    <detail-item :name="$t('guide.beer.afterTaste')">{{ beerData.AfterTaste }}</detail-item>
  </ul>
</template>
<script>
import detailItem from './detailItem.vue';

export default {
  name: 'beer-details',

  components: {
    detailItem,
  },

  props: {
    beerData: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
ul.details {
  margin: 2rem 0 0 0;
  column-count: 1;
  list-style: none;

  @media screen and (min-width: 75rem) {
    column-count: 2;
  }
}
</style>
