<template>
  <div v-if="questions && step < questions.length">
    <div class="question">{{ question.Question }}</div>
    <form @submit.prevent="handleAnswer">
      <div v-for="answer in question.Answers" :key="answer.id">
        <input type="radio" v-model="selectedId" :id="answer.id" :value="answer.id" />
        <label :for="answer.id">{{ answer.Answer }}</label>
      </div>
      <styled-button type="submit" centered :disabled="!selectedId">
        {{ $t('guide.buttons.sendAnswer') }}
      </styled-button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import styledButton from '@/components/common/styledButton';

export default {
  name: 'questions',

  components: {
    styledButton,
  },

  props: {
    quizId: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      step: 0,
      selectedId: undefined,
      quizAnswers: {},
    };
  },

  computed: {
    ...mapGetters({
      journeyId: 'guide/getJourneyId',
    }),
    question() {
      return this.questions[this.step];
    },
  },

  mounted() {
    this.quizAnswers = JSON.parse(localStorage.getItem(`${this.journeyId}-quiz`) || '{}');
    this.quizAnswers[this.quizId] = this.quizAnswers[this.quizId] || {};
    this.step = this.questions.concat({}).findIndex((q) => !this.quizAnswers[this.quizId][q.id]);
    this.handleSubmit();
  },

  methods: {
    handleAnswer() {
      this.quizAnswers[this.quizId][this.question.id] = this.selectedId;
      this.selectedId = undefined;
      this.step += 1;

      localStorage.setItem(`${this.journeyId}-quiz`, JSON.stringify(this.quizAnswers));
      this.handleSubmit();
    },

    handleSubmit() {
      if (this.step === this.questions.length) {
        this.$emit('submit', this.quizAnswers[this.quizId]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  font-size: 1.5rem;
  font-weight: bolder;
  margin: 3rem 0 1rem;
}

form {
  font-size: 1.5rem;
  margin: 1rem 0;

  label {
    margin-left: 0.5rem;
  }
}

button {
  margin-top: 2rem;
}

input,
label {
  cursor: pointer;
}
</style>
