<template>
  <div class="rating-background" :class="{ clickable, vertical }" :style="cssVars">
    <div class="wrapper" v-for="index in 5" :key="index" @click="handleClick($event, index)">
      <icon :name="name" />
      <div class="filled" :style="getStyle(index)">
        <icon :name="name" />
      </div>
    </div>
  </div>
</template>

<script>
import icon from 'vue-awesome/components/Icon.vue';

export default {
  name: 'ratingStars',

  components: {
    icon,
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'star',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    noStroke: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '2rem',
    },
  },

  computed: {
    cssVars() {
      return {
        '--size': this.size,
        '--stroke': this.noStroke ? '0' : '1px',
      };
    },
  },

  methods: {
    getStyle(index) {
      const key = this.vertical ? 'height' : 'width';
      let fraction = Math.max(Math.min(this.value - index + 1, 1), 0);
      if (fraction > 1e-5 && fraction < 1 - 1e-5 && !this.vertical) {
        fraction = 0.8 * fraction + 0.1;
      }
      return { [key]: `${100 * fraction}%` };
    },

    handleClick(e, index) {
      if (this.clickable) {
        this.$emit('change-rating', index);
      }
    },
  },
};
</script>

<style>
.rating-background path {
  vector-effect: non-scaling-stroke;
}
</style>

<style lang="scss" scoped>
svg {
  height: var(--size);
  width: var(--size);
  fill-opacity: 0.2;
  stroke: gold;
  stroke-width: var(--stroke);
}
.filled {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: var(--size);
  width: var(--size);
  transition: height 0.3s;
  svg {
    position: absolute;
    bottom: 0;
    fill: gold;
    fill-opacity: 1;
  }
}
.rating-background {
  margin: 0.5rem 0;
  display: inline-block;
  line-height: 0;
}
.wrapper {
  position: relative;
  display: inline-block;
  height: var(--size);
  width: var(--size);
  border-left: calc(var(--size) / 10) solid transparent;
  border-right: calc(var(--size) / 10) solid transparent;
  box-sizing: content-box;
}
.clickable {
  cursor: pointer;

  &:hover .filled {
    height: var(--size) !important;
    width: var(--size) !important;
  }

  &.vertical .wrapper:hover ~ div > .filled {
    height: 0 !important;
  }

  &:not(.vertical) .wrapper:hover ~ div > .filled {
    width: 0 !important;
  }
}
</style>
